import React from 'react';
import style from './About.module.css';
import AboutCard from '../Cards/AboutCard';
import Button from '../Button/Button';
import { faWallet, faThumbsUp, faHandshake, faBusinessTime, faTools } from '@fortawesome/free-solid-svg-icons';

const About = () => {
    return (
        <div id="about" className={style.Container}>
            <div className="wrapper">
                <div className={style.CardSection}>
                    <AboutCard 
                        icon={faWallet}
                        title="No Upfront Payments"
                    />
                    <AboutCard 
                        icon={faThumbsUp}
                        title="Satisfaction Guarantee"
                    />
                    <AboutCard 
                        icon={faBusinessTime}
                        title="Emergency Service"
                    />
                    <AboutCard 
                        icon={faHandshake}
                        title="Fixed Right Promise"
                    />
                    <AboutCard 
                        icon={faTools}
                        title="10+ Years Experience"
                    />
                </div>
                <div className={style.AboutCompany}>
                    <div className={style.ImageContainer}>
                        <div className={style.Image}></div>
                    </div>
                    <div className={style.Text}>
                        <h4>Insulation Master Installation and Repair</h4>
                        <h1>The Insulation Experts</h1>
                        <p>Insulation might seem not crucial for comfortable living. However to have a home properly insulated is necessary. Insulation keeps a house warmer in winter months and cooler in the summer. It helps to reduce an over usage of heating and cooling system of the house thus helps to save money on energy bills each month.</p>
                        <Button link="https://www.facebook.com/pages/category/Contractor/Insulation-Master-100363908254767" label="Learn more"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;