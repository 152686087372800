import React, { Component } from 'react';
import style from './Contact.module.css';
import Button from '../Button/Button';

class Contact extends Component {
    state = {
        name: '',
        email: '',
        phone: '',
        message: ''
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    onEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    onPhoneChange = (event) => {
        this.setState({
            phone: event.target.value
        })
    }

    onMessageChange = (event) => {
        this.setState({
            message: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        fetch('https://8p2sp46uw3.execute-api.us-east-1.amazonaws.com/dev', {
            method: 'POST',
            body: JSON.stringify({
                source: 'insulation_contact',
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            })
        })
        .then(res => res.json())
        .then(res => alert('Your email has been submitted.  Thank you for contacting us!'))
        .then(res => window.location.reload())
        .then(res => window.scrollTo(0,0))
        .catch(err => console.error('Error:', err))
    }

    render(){
        return (
            <div className={style.Container}>
                <div className={style.ContactMap}>
                    <h1>Send your requests here - our work is guaranteed to satisfy.</h1>
                    <div className={style.Map}>
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.5221751942395!2d-88.15170968455473!3d42.010529279211916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fa9c76e91a449%3A0xef24e44bc459e250!2s1086%20Frances%20Dr%2C%20Streamwood%2C%20IL%2060107!5e0!3m2!1sen!2sus!4v1576740440773!5m2!1sen!2sus" frameBorder="0" style={{border: 0}} allowFullScreen=""></iframe>
                    </div>
                </div>
                <div id="contact" className={style.ContactForm}>
                    <h4>Get a free quote</h4>
                    <h1>To request a service call, please fill out the form bellow</h1>
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type="text"
                            className={style.halfLeft}
                            placeholder="Your name"
                            value={this.state.value}
                            onChange={this.onNameChange}
                        ></input>
                        <input
                            type="email"
                            className={style.halfRight}
                            placeholder="Email address"
                            value={this.state.value}
                            onChange={this.onEmailChange}
                        ></input>
                        <input
                            type="number"
                            className={style.full}
                            placeholder="Phone number"
                            value={this.state.value}
                            onChange={this.onPhoneChange}
                        ></input>
                        <input
                            type="text"
                            className={style.full}
                            placeholder="Message"
                            value={this.state.value}
                            onChange={this.onMessageChange}
                        ></input>
                        <button type="submit">Submit Now</button>
                    </form>
                </div>
            </div>
        );
    }
};

export default Contact;