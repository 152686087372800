import React from 'react';
import style from './Gallery.module.css';
import galleryimg1 from '../../images/62239352_2369284409804118_5521527960516952064_n.jpg';
import galleryimg2 from '../../images/17352183_1371950006204235_7537108896182922242_n.jpg';
import galleryimg3 from '../../images/50701231_2158332064232688_7483299984301883392_o.jpg';
import galleryimg4 from '../../images/17352541_1371949959537573_9204997324950772101_n.jpg';
import galleryimg5 from '../../images/48404827_2110622125670349_4167936414085808128_n.jpg';
import galleryimg6 from '../../images/50839944_2156692457729982_2730037223729659904_n.jpg';

const Gallery = () => {
    return (
        <div className={style.Container}>
            <img alt='galleryimg' src={galleryimg1} />
            <img alt='galleryimg' src={galleryimg2} />
            <img alt='galleryimg' src={galleryimg3} />
            <img alt='galleryimg' src={galleryimg4} />
            <img alt='galleryimg' src={galleryimg5} />
            <img alt='galleryimg' src={galleryimg6} />
        </div>
    );
};

export default Gallery;