import React from 'react';
import style from './Services.module.css';
import ServicesCard from '../Cards/ServicesCard';
import { faToolbox, faHome, faBuilding } from '@fortawesome/free-solid-svg-icons';
import img1 from '../../images/services1.jpeg';
import img2 from '../../images/services2.jpeg';
import img3 from '../../images/services3.jpeg';

const Services = () => {
    return (
        <div id="services" className={style.Container}>
            <div className="wrapper">
                <div className={style.Services}>
                    <h1>Our services</h1>
                    <div className={style.CardSection}>
                        <ServicesCard 
                            img={img2}
                            icon={faHome}
                            title="Residential Insulation"
                            content="Proper insulation provides numerous benefits, such as pest and mold control, moisture barriers, soundproofing, and fire-resistance.  Stop wasting money on energy bills!"
                        />
                        <ServicesCard
                            img={img1}
                            icon={faBuilding}
                            title="Commercial Insulation"
                            content="We understand the added complexities of commercial insulation projects and stay well informed of the changing codes and guidelines.  We are a phone call away for your business!"
                        />
                        <ServicesCard 
                            img={img3}
                            icon={faToolbox}
                            title="Other Services"
                            content="We work with everything related to insulation and not just what you see here.  Whether a new or aged home or business, we have the experience needed to provide you comfort!"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;